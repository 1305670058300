/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        //functions
        imagesLoaded( 'body', function() {
            $("#preloader").fadeOut("fast");
            $("body").removeClass("preload");
        });

		function pad (str, max) {
		    str = str.toString();
		    return str.length < max ? pad("0" + str, max) : str;
		  }

        $(document).foundation(); // Foundation JavaScript

        WebFontConfig = {
          google: { families: [ 'Playfair+Display:400,400italic,700,700italic,900,900italic:latin,latin-ext', 'Open+Sans:400,300,600,700:latin,latin-ext' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

		$( '.ga_send_open' ).click( function(){
			ga( 'send', 'event', 'Bon podarunkowy', 'Otwarcie popupu' );
		});

		wow = new WOW ({
			mobile: false
		});
		wow.init();

		if ($('#map').length){
		  var width = $(window).width();

		  if (width <= 1024) {
			is_mobile = false;
		  } else {
			is_mobile = true;
		  }

		  // Basic options for a simple Google Map
		  // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
		  var mapOptions = {
			// How zoomed in you want the map to start at (always required)
			zoom: 14,
			draggable: is_mobile,
			scrollwheel: false,
			// The latitude and longitude to center the map (always required)
			center: new google.maps.LatLng(50.849650, 16.486383), // tu podaj adres

			// How you would like to style the map.
			// This is where you would paste any style found on Snazzy Maps.
			styles:
			[{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
		  };

		  // Get the HTML DOM element that will contain your map
		  // We are using a div with id="map" seen below in the <body>
		  var mapElement = document.getElementById('map');

		  // Create the Google Map using our element and options defined above
		  var map = new google.maps.Map(mapElement, mapOptions);

		  // Let's also add a marker while we're at it
		  var marker = new google.maps.Marker({
			position: new google.maps.LatLng(50.849650, 16.486383),
			map: map,
			icon: template_url + "/dist/images/point-svg.svg",
			title: 'Dent&Beauty ul. Trakt Węgierski 203, Świdnica 70-878'
		  });
	  	}

		$("#mainul").hide();
        $(".hamburger").click(function() {
          $(this).toggleClass("is-active");
		  var width = $(window).width();

		  if ( width > 1024 ) {
			  if ( $("#mainul").is(':visible') ) {
				 $("#mainul").fadeOut( 400, 'swing', function(){
					 $("#adresul").fadeIn();
				 });
			  }
			  if ( $("#adresul").is(':visible') ) {
				  $("#adresul").fadeOut( 400, 'swing', function(){
	 				 $("#mainul").fadeIn();
	 			 });
			  }
	  	  } else {
			  $( '#mainul' ).fadeToggle( 400 );
		  }

		  if (width < 1024) {
			$( 'body' ).toggleClass( 'preload' );
		  } else {
			$( 'body' ).removeClass( 'preload' );
		  }
        });

		// jakiś js do pojedynczego klika na iphone...
		/*$('.kadra a').on('click touchend', function(e) {
		    var el = $(this);
		    var link = el.attr('href');
		    window.location = link;
		});*/

		$( '.slider_person' ).on( 'click touchend', function(){
			var imie = $(this).data( 'person' );
			var spec = $(this).data( 'spec' );
			var opis = $(this).data( 'description' );

			$( '#slider_spec' ).hide().html( spec ).fadeIn();
			$( '#slider_person' ).hide().html( imie ).fadeIn();
			$( '#slider_description' ).hide().html( opis ).fadeIn();
			return false;
		});

		if( window.location.hash.substring(1) !== '' ) {
			var category = 'is-promo';
			$( 'h5.zabieg_sub' ).show();

			$( 'h5.zabieg_sub' ).each( function(){
				var count = $(this).parent().find( '.is-promo' ).length;
				if ( count === 0 ) {
					$(this).hide();
				}
			});

			$( '.zabieg' ).show();
			$( '.zabieg' ).not( '.'+ category).hide();
		}

		$( '.przecenione' ).on( 'click', function() {
			var category = 'is-promo';
			$( 'h5.zabieg_sub' ).show();

			$( 'h5.zabieg_sub' ).each( function(){
				var count = $(this).parent().find( '.is-promo' ).length;
				if ( count === 0 ) {
					$(this).hide();
				}
			});

			$( '.zabieg' ).show();
			$( '.zabieg' ).not( '.'+ category).hide();
		});

		$( '#category' ).on( 'change', function() {
			var category = $(this).val();
			$( '.kat_zabieg' ).show();
			$( '.kat_zabieg' ).not( '.'+ category).hide();

			return false;
		} );

		// fixed menu po scrollu
		var nav_height = $("#mainnav").height();
		var nav_scroll = jQuery(document).scrollTop();
		if(nav_scroll>nav_height){
		   jQuery('#mainnav').addClass('addbg');
		} else {
		   jQuery('#mainnav').removeClass('addbg');
		}

		jQuery( window ).scroll(function() {
			var nav_height = $("#mainnav").height();
			var nav_scroll = jQuery(document).scrollTop();
			if(nav_scroll>nav_height){
			   jQuery('#mainnav').addClass('addbg');
			} else {
			   jQuery('#mainnav').removeClass('addbg');
			}
		});

		$( "#cookies-box .close" ).click(function() {
		  Cookies.set( 'cookies_set', true );
		  $( "#cookies-box" ).fadeOut();
		});

		$( "#cookies-box .pokazdetale" ).click(function() {
		  $( "#cookies-box .detale" ).slideDown();
		  $( this ).hide();
		});

		$( "#cookies-box .ukryjdetale" ).click(function() {
		  $( "#cookies-box .detale" ).slideUp();
		  $( "#cookies-box .pokazdetale" ).fadeIn();
		});

		var cookies_set = Cookies.get( 'cookies_set' );
		console.log( cookies_set );
		if( cookies_set === 'true' ) {
			$( "#cookies-box" ).hide();
		} else {
      $( "#cookies-box" ).show();
    }

		$(".scrollarrow").click(function() {
			$('html, body').animate({
				scrollTop: $("#first").offset().top - nav_height
			}, 1000);
		});

		$(".select-chosen").niceSelect();

		$('.category-box .category-menu').not('.no-hide').slideUp();

		$('.category-box .h6').click(function(){
			if( $(this).closest('.category-box').children('.category-menu').is(':visible') ) {
				$('.category-menu').slideUp( 400 );
			} else {
				$('.category-menu').slideUp( 400 );
				$(this).closest('.category-box').children('.category-menu').slideDown( 400 );
			}
		});

		$( '.subkat_link' ).click( function() {
			if( $(this).next('ul').is(':visible') ) {
				console.log( 'a' );
				$('ul.hide-not-important').slideUp( 400 );
			} else {
				console.log( 'b' );
				$('ul.hide-not-important').slideUp( 400 );
				$(this).next('ul').show( 400 );
			}
		});

		imagesLoaded( '.category-slider', function() {
			var $carousel_slider = $('.category-slider').flickity({
			  cellAlign: 'left',
			  contain: true,
			  prevNextButtons: true,
			  wrapAround: true,
			  pageDots: false
			});

			var flkty = $carousel_slider.data( 'flickity' );

			$carousel_slider.on( 'cellSelect', function() {
			  i = pad ( flkty.selectedIndex + 1, 2 );
			  $( '#current-number' ).html( i );
			});
		});

		imagesLoaded( '.kadra-slider', function() {
			$('.kadra-slider').flickity({
			  cellAlign: 'left',
			  contain: true,
			  prevNextButtons: true,
			  pageDots: false
			});
		});

		imagesLoaded( '.header-slider', function() {
			$('.header-slider').flickity({
			  cellAlign: 'left',
			  contain: true,
			  prevNextButtons: true,
			  wrapAround: true,
			  pageDots: false,
			  autoPlay: 5000
			});
		});

		imagesLoaded( '.partnerzy-slider', function() {
			$('.partnerzy-slider').flickity({
			  cellAlign: 'left',
			  contain: true,
			  prevNextButtons: true,
			  wrapAround: false,
			  pageDots: false,
			  autoPlay: true
			});
			new Foundation.Equalizer($(".partnerzy_row")).applyHeight();
		});

		$('.metamorfozy-photo').magnificPopup({
			delegate: 'a',
  			type: 'image',
			gallery: {
				enabled: true
			},
		});

		function calclulate_price() {

			var wysylka = parseInt( $( '#wysylka' ).val() );
			var cena = parseInt( $( '#wartosc' ).val() );
			var inna = $('#wlasna_wartosc').val();

			if( '' !== inna ) {
				inna = parseInt( $('#wlasna_wartosc').val() );
				cena = inna;
			}

			var suma = wysylka + cena;

			$( '#price span' ).html( suma );
		}

		$('.bonpodarunkowy .kwota_bonu .change-color').click(function(){
		  $(".bonpodarunkowy .kwota_bonu .change-color").removeClass("choose");
		  $(this).addClass("choose");
		  $( '#wartosc' ).val( $(this).data( 'value' ) );
		  $( '#wlasna_wartosc' ).val('');
		  calclulate_price();
		});

		$('#wlasna_wartosc').on( 'change', function(){
		  $(".bonpodarunkowy .kwota_bonu .change-color").removeClass("choose");

		  calclulate_price();
		});

		$('.bonpodarunkowy .typ_bonu .change-color').click(function(){
		  $(".bonpodarunkowy .typ_bonu .change-color").removeClass("choose");
		  $(this).addClass("choose");
		  $( '#typ' ).val( $(this).data( 'value' ) );

		  $( '#wysylka' ).val( $(this).data('price') );
		  calclulate_price();
		});

		$( '#bonpodarunkowy_wizualizacja' ).hide();
        $( '.zobacz-bon' ).click(function() {
          $( '#bonpodarunkowy_inner' ).hide();
		  $( '#bonpodarunkowy_wizualizacja' ).show();
        });

		$( '#bonpodarunkowy_wizualizacja .close-button' ).click(function() {
          $( '#bonpodarunkowy_inner' ).show();
		  $( '#bonpodarunkowy_wizualizacja' ).hide();
        });

		$('.popupopen').on( 'click', function(){
			$("body").addClass("preload");

			var popup_id = $(this).data('popup');
			var iframe = $( popup_id ).data( 'ytid' );
			var iframe_src = "https://www.youtube.com/embed/"+iframe+"?rel=0&autoplay=1";
			$( popup_id + ' iframe').attr( 'src', iframe_src );

            $( popup_id).removeClass("fadeOut").removeClass("animated").addClass("fadeIn").addClass("animated").show();

			return false;
		});

		$( '.popup-close' ).click(function(){
			$( 'body' ).removeClass( 'preload' );
			var popup_id = $(this).data( 'popup' );
			$( popup_id + ' iframe' ).attr( 'src', '#' );
            $( popup_id ).removeClass("fadeIn").removeClass("animated").addClass("fadeOut").addClass("animated").hide();

		});

		$( 'a[data-open^="bonpodarunkowy"]' ).on( 'click', function(){
			$( '#bon_success' ).hide();
			$( '#bon_form' ).show();
		});

		$(window).load(function() {
			var viewportWidth = $(window).width();
			if (viewportWidth < 1024) {
				$(".select-chosen").removeClass("wide");
			}
		});

		$(window).resize(function () {
			var viewportWidth = $(window).width();
			if (viewportWidth < 1024) {
				$(".select-chosen").removeClass("wide");
			}
		});

		$(window).load(function() {
			var viewportWidth = $(window).width();
			if (viewportWidth < 1024) {
				$(".select-chosen.add-wide").addClass("wide");
			}
		});

		$(window).resize(function () {
			var viewportWidth = $(window).width();
			if (viewportWidth < 1024) {
				$(".select-chosen.add-wide").addClass("wide");
			}
		});

		// Replace all SVG images with inline SVG
		jQuery('img.svg').each(function(){
		 var $img = jQuery(this);
		 var imgID = $img.attr('id');
		 var imgClass = $img.attr('class');
		 var imgURL = $img.attr('src');

		 jQuery.get(imgURL, function(data) {
		     // Get the SVG tag, ignore the rest
		     var $svg = jQuery(data).find('svg');

		     // Add replaced image's ID to the new SVG
		     if(typeof imgID !== 'undefined') {
		         $svg = $svg.attr('id', imgID);
		     }
		     // Add replaced image's classes to the new SVG
		     if(typeof imgClass !== 'undefined') {
		         $svg = $svg.attr('class', imgClass+' replaced-svg');
		     }

		     // Remove any invalid XML tags as per http://validator.w3.org
		     $svg = $svg.removeAttr('xmlns:a');

		     // Replace image with new SVG
		     $img.replaceWith($svg);

		 }, 'xml');
		});

		$( '.select-chosen-form' ).on( 'change', function(){
			$(this).closest( 'form' ).submit();
		} );


		$( '#bon_form' ).on( 'submit', function() {
			var imie = $( '#imie_nazwisko' ).val();
			var imie_zamawiajacego = $( '#imie_nazwisko_zamawiajacego' ).val();
			var ulica = $( '#ulica' ).val();
			var kod = $( '#kod' ).val();
			var email = $( '#email' ).val();
			var wartosc = $( '#wartosc' ).val();
			var typ = $( '#typ' ).val();
			var wysylka = $( '#wysylka'  ).val();
			var contact_email = $( '#contact_email' ).val(); // honeypot

			if ( imie !== '' && ulica !== '' && kod !== '' && email !== '' && wartosc > 0 && typ !== 0 && contact_email === '' ) {
				var data = {
				  	'action': 'ajax_send_mail',
				  	'imie_zamawiajacego': imie_zamawiajacego,
					'imie': imie,
				  	'ulica': ulica,
					'kod': kod,
					'email': email,
					'wartosc': wartosc,
					'typ': typ,
					'wysylka': wysylka,
			    };

			    $.post(ajax_url, data, function (response) {
					$( '#errors' ).html( '' );
					if( response === "1" ){
						ga( 'send', 'event', 'Bon podarunkowy', 'Wysłanie formularza' );

					 	$( '#bon_success' ).fadeIn();
						$( '#bon_form' ).hide();

					} else {
						$( '#form_resp' ).hide().html( response ).fadeIn();
					}

			    });
			} else {
				$( '#errors' ).html( '<em>Należy wypełnić wszystkie pola.</em>');
			}

			return false;
		});


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // FB
        (function(d, s, id) {
					var js, fjs = d.getElementsByTagName(s)[0];
					if (d.getElementById(id)){return;}
					js = d.createElement(s); js.id = id;
					js.src = "//connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v2.7&appId=354696121251703";
					fjs.parentNode.insertBefore(js, fjs);
				}(document, 'script', 'facebook-jssdk'));
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'kontakt': {
		init: function() {
	      // JavaScript to be fired on the home page

	    },
	    finalize: function() {
	      // JavaScript to be fired on the home page, after the init JS
	    }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
